.progressBar {
  width: 100%;
  height: 3px;
  display: inline-block;
  position: relative;
  overflow: hidden;
}
.progressBar::after {
  content: "";
  width: 192px;
  height: 3px;
  background: #100160;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  animation: progressLoader 2s linear infinite;
}

@keyframes progressLoader {
  0% {
    left: 0;
    transform: translateX(-100%);
  }
  100% {
    left: 100%;
    transform: translateX(0%);
  }
}
