* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  outline-offset: 0;
}

body {
  background: #f3f4f6;
}

.sidebar {
  overflow-y: hidden;
}

.sidebar:hover {
  overflow-y: auto;
}

.sidebar::-webkit-scrollbar {
  width: 8px;
  border-radius: 10px;
}

.sidebar::-webkit-scrollbar-track {
  background: #100160;
  border-radius: 10px;
}

.sidebar::-webkit-scrollbar-thumb {
  background: #7966e3;
  border-radius: 10px;
}

.sidebar::-webkit-scrollbar-thumb:hover {
  background: #6553ca;
}

.divider {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 1rem 0;
}

.divider::before,
.divider::after {
  content: "";
  flex: 1;
  border-bottom: 2px dashed #ccc;
}

.divider:not(:empty)::before {
  margin-right: 0.25em;
}

.divider:not(:empty)::after {
  margin-left: 0.25em;
}

@media print {
  @page {
    margin: 15px 0; /* Adjust the margin values as needed */
  }
  body {
    padding: 30px 0; /* Adjust the padding values as needed */
  }

  .page-break-before {
    display: block;
    page-break-before: always;
  }

  .page-break-after {
    display: block;
    page-break-after: always;
  }

  .chart-container {
    display: block !important;
    width: 100% !important;
    height: auto !important;
  }
}
